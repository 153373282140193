import { Dropdown, Input, Spin } from "antd"
import ArrowBottom from "assets/icons/ArrowBottom"
import CheckIcon from "assets/icons/CheckIcon"
import Location1Icon from "assets/icons/Location1Icon"
import SearchIcon from "assets/icons/SearchIcon"
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks"
import { useRouter } from "next/router"
import { useGetAllRegion } from "pages/main-layout/components/region-city/services/queries"
import {
  useGetRegionCity,
  useGetRegionDistricts,
} from "pages/main-layout/services/queries"
import React, { useState } from "react"
import { favouritesReducerActions } from "store/reducers/favouritesReducer"
import { layoutLang } from "utils/language/layout"
import NonSSRWrapper from "common/non-ssr/NonSSRWrapper"

import styles from "./hot-offers.module.scss"
import { cx } from "utils/cx"


const HotOffersLocation = () => {
  const dispatch = useAppDispatch()
  const { locale, query } = useRouter()
  const [visible, setVisible] = useState(false)
  const [time, setTime] = useState<any>()
  const [val, setVal] = useState("")
  const { setOpenRegionSelect, setCurrentRegion, setSearchValue } =
    favouritesReducerActions
  const { location, hotOffersRegionValue } = useAppSelector(
    (state) => state.favouritesReducer
  )

  const { data, isFetching } = useGetAllRegion(location?.searchValue)
  const { data: region } = useGetRegionCity()
  const { data: district } = useGetRegionDistricts(Number(query?.city_id), 1)
  const currentRegion = region?.find(
    (item) => item?.id === Number(query?.city_id)
  )
  const currentDistrict = district?.find(
    (item) => item?.id === Number(query?.district_id)
  )

  const handleChangeSearch = (value: string) => {
    setVal(value)
    clearTimeout(time)
    if (value) {
      setTime(
        setTimeout(() => {
          dispatch(setSearchValue(value))
        }, 800)
      )
    } else {
      dispatch(setSearchValue(value))
    }
  }

  const locationName = hotOffersRegionValue
    ? hotOffersRegionValue?.city
      ? hotOffersRegionValue?.city?.name[
      locale as keyof typeof hotOffersRegionValue.city.name
      ]
      : `${hotOffersRegionValue?.district?.name[
      locale as keyof typeof hotOffersRegionValue.district.name
      ]
      }`
    : query?.district_id
      ? currentDistrict?.name[locale as keyof typeof currentDistrict.name]
      : query?.city_id
        ? currentRegion?.name[locale as keyof typeof currentRegion.name]
        : layoutLang[String(locale)]["Butun_Ozbekiston_boylab"]

  return (
    <div className="cursor-pointer">
      <div className="md:block hidden">
        <Dropdown
          overlay={
            <div
              className={`border border-solid border-[#EAECF0] rounded-xl bg-white w-[360px]  mobileTopFilter`}
              style={{
                boxShadow:
                  "0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814",
              }}
            >
              <Input
                prefix={<SearchIcon color="#98A2B3" size="18" />}
                placeholder={`${layoutLang[String(locale)]["Qidirish"]} ...`}
                value={val}
                onChange={(e) => {
                  handleChangeSearch(e?.target?.value)
                }}
                className="h-[43px] rounded-xl border-none shadow-none px-4"
              />
              <div className="flex flex-col p-3 bg-white border-t border-solid border-[#EAECF0]">
                <div
                  className="flex items-center gap-3 px-1 pb-3 border-b border-solid  border-[#EAECF0] cursor-pointer"
                  onClick={() => {
                    dispatch(
                      setCurrentRegion({
                        type: "hot_location",
                        value: { city: null, district: null },
                      })
                    )
                    setVisible(false)
                  }}
                >
                  <Location1Icon />
                  <div className="flex flex-col">
                    <span className="text-[#344054] font-medium">
                      {layoutLang[String(locale)]["Butun_Ozbekiston_boylab"]}
                    </span>
                  </div>
                </div>
                <Spin spinning={isFetching}>
                  <div
                    className={cx(
                      "max-h-[300px] overflow-y-auto",
                      styles.regions
                    )}
                  >
                    {data?.map((item, index) => (
                      <div key={index}>
                        {item?.district ? (
                          <div
                            className="flex items-center gap-3 px-1 py-3 border-b border-solid  border-[#EAECF0] cursor-pointer"
                            onClick={() => {
                              dispatch(
                                setCurrentRegion({
                                  type: "hot_location",
                                  value: item,
                                })
                              )
                              setVisible(false)
                            }}
                          >
                            <Location1Icon />
                            <div className="flex flex-col">
                              <span className="text-[#344054] font-medium">
                                {
                                  item?.district?.name?.[
                                  locale as keyof typeof item.district.name
                                  ]
                                }
                              </span>
                              <span className="text-[#667085] text-xs">
                                {
                                  item?.district?.city?.name[
                                  locale as keyof typeof item.district.city.name
                                  ]
                                }
                              </span>
                            </div>
                            {hotOffersRegionValue?.district?.id ===
                              item?.district?.id && (
                                <div>
                                  <CheckIcon />
                                </div>
                              )}
                          </div>
                        ) : (
                          <div
                            className="flex items-center gap-3 px-1 py-3 border-b border-solid border-[#EAECF0] cursor-pointer"
                            onClick={() => {
                              dispatch(
                                setCurrentRegion({
                                  type: "hot_location",
                                  value: item,
                                })
                              )
                              setVisible(false)
                            }}
                          >
                            <Location1Icon />
                            <span>
                              {
                                item?.city?.name[
                                locale as keyof typeof item.city.name
                                ]
                              }
                            </span>
                            {hotOffersRegionValue?.city?.id ===
                              item?.city?.id && (
                                <div>
                                  <CheckIcon />
                                </div>
                              )}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </Spin>
              </div>
            </div>
          }
          trigger={["click", "hover"]}
          mouseEnterDelay={1000}
          mouseLeaveDelay={0.3}
          open={visible}
          onOpenChange={() => setVisible(!visible)}
        >
          <div className={`flex items-center gap-1 w-max md:w-auto`}>
            <span className="text-[#1E90FF] leading-[19px] font-medium truncate w-[95%]">
              {locationName}
            </span>
            <ArrowBottom stroke="#1E90FF" />
          </div>
        </Dropdown>
      </div>
      <NonSSRWrapper>
        <div
          className={`md:hidden flex items-center gap-1 w-max md:w-auto`}
          onClick={() =>
            dispatch(setOpenRegionSelect({ type: "hot_location", value: true }))
          }
        >
          <span className="text-[#1E90FF] leading-[19px] font-medium truncate w-[95%]">
            {locationName}
          </span>
          <ArrowBottom stroke="#1E90FF" />
        </div>
      </NonSSRWrapper>
    </div>
  )
}

export default HotOffersLocation
