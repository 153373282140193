import { useTranslations } from "next-intl"
import { useRouter } from "next/router"
import React, { useEffect, useMemo } from "react"

import ComplexCard from "../../../../common/common-card/complex/ComplexCard"
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks"
import { userReducerActions } from "../../../../store/reducers/userReducer"
import { rootPaths } from "../../../../utils/constants/rootPaths"
import { viewTypes } from "../../../../utils/constants/viewTypes"
import { urls } from "utils/constants/urls"
import ViewAdv from "../../../../common/view-adv/ViewAdv"
import HotOffersMobile from "../hot-offers/HotOffersMobile"
import styles from "./NewComplexes.module.scss"
import NonSSRWrapper from "common/non-ssr/NonSSRWrapper"
import CustomLink from "common/custom-link"
import {
  BannerImageType,
  BannerPosition,
  IBanner,
  IBannerImage,
} from "pages/app/utils/models/banner"
import { Pagination } from "utils/models/pagination"
import { NewResidentalComplexModel } from "utils/models/residentialComplexModel"
import Image from "next/image"

type Props = {
  banner: Partial<
    Record<BannerPosition, IBanner<Record<BannerImageType, IBannerImage>>>
  >
  topComplexes: Pagination<NewResidentalComplexModel>
}

const MainNewComplexes: React.FC<Props> = ({
  banner: banners,
  topComplexes: data,
}) => {
  const t = useTranslations("body.newComplexes")
  const dispatch = useAppDispatch()
  const tMainTop = useTranslations("body.mainTop")
  const { locale } = useRouter()
  const { setUserAddress } = userReducerActions
  const { userAddress } = useAppSelector((state) => state.userReducer)

  useEffect(() => {
    dispatch(setUserAddress(userAddress))
  }, [userAddress,dispatch,setUserAddress])

  // location
  const location = useMemo(() => userAddress?.url_name, [userAddress])

  return (
    <section className={styles.new_buildings}>
      <div className="wrapper">
        <NonSSRWrapper>
          <div className="mobile_hot_offers">
            <HotOffersMobile />
          </div>
        </NonSSRWrapper>
        <h2 className={styles.title}>{t("Популярные новостройки")}</h2>
        <div className={styles.body}>
          {banners?.right_side?.images?.web?.url && (
            <ViewAdv uuid={banners?.right_side?.uuid}>
              <a
                className={styles.reklama_right}
                target={banners?.right_side?.blank ? "_blank" : "_self"}
                href={`${urls.BASE_ADS_UYSOT}/api/banner/view?uuid=${banners?.right_side?.uuid}&lang=${locale}`}
              >
                <div className={styles.reklama_tag}>
                  <span>{tMainTop("Реклама")}</span>
                </div>
                <Image src={banners?.right_side?.images?.web?.url} alt="banner image" fill />
              </a>
            </ViewAdv>
          )}
          {data?.data?.slice(0, 6)?.map((item) => (
            <ComplexCard data={item} key={item?.id} viewType={viewTypes.GRID} />
          ))}
        </div>
        <div className={styles.bottom}>
          <CustomLink href={`/${location}${rootPaths.RESIDENTIAL_COMPLEXES}`}>
            {t("Показать еще")}
          </CustomLink>
        </div>
      </div>
    </section>
  )
}

export default MainNewComplexes
