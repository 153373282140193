import { useQuery } from "@tanstack/react-query"
import { errorHandler } from "../../../utils/helpers/errorHandler"
import { NewResidentalComplexModel } from "../../../utils/models/residentialComplexModel"
import { ErrorRes } from "../../../utils/models/responseType"
import { MagazineType } from "../../magazines/utils/models/magazineModel"
import { queryKeys } from "../utils/constants/queryKeys"
import {
  fetchApartmentCounts,
  fetchHotFilters,
  fetchTopBuilders,
  fetchTopComplexes,
  fetchTopMagazines,
} from "./queryFunctions"
import { useRouter } from "next/router"
import { Pagination } from "utils/models/pagination"
import { HotFiltersModel } from "pages/magazines/utils/models/hotFiltersModel"
import { OneBuilderModel } from "pages/builders/utils/models/oneBuilderModel"

// get top residential complexes
export function useGetTopResidentialComplexes() {
  const { url, fetch } = fetchTopComplexes()

  return useQuery<Pagination<NewResidentalComplexModel>, ErrorRes>({
    queryKey: [queryKeys.TOP_COMPLEXES, url],
    queryFn: fetch,
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler,
  })
}

// get apartment counts
export function useGetApartmentCounts() {
  return useQuery<{ rooms_count: number; apartments_count: number }[], ErrorRes>({
    queryKey: [queryKeys.APARTMENT_COUNTS],
    queryFn: fetchApartmentCounts,
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler,
    staleTime: Infinity
  });
}

// get top builders
export function useGetTopBuilders() {
  return useQuery<OneBuilderModel[], ErrorRes>({
    queryKey: [queryKeys.TOP_BUILDERS],
    queryFn: fetchTopBuilders,
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler,
    staleTime: Infinity
  });
}

// get top magazines
export function useGetTopMagazines(article_ids?: number[]) {
  const { locale } = useRouter();
  return useQuery<MagazineType[], ErrorRes>({
    queryKey: [queryKeys.NEW_TOP_MAGAZINES, locale],
    queryFn: () => {
      return fetchTopMagazines(locale, article_ids);
    },
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler,
    staleTime: Infinity
  });
}

// get hot filters

export function useGetHotFilters(city_id?: number, district_id?: number) {
  const hotQueryKeys =
    city_id && district_id!
      ? [queryKeys.HOT_FILTERS, Number(city_id), Number(district_id)]
      : city_id
        ? [queryKeys.HOT_FILTERS, Number(city_id)]
        : [queryKeys.HOT_FILTERS];

  return useQuery<HotFiltersModel[], ErrorRes>({
    queryKey: hotQueryKeys,
    queryFn: () =>
      fetchHotFilters(city_id ? Number(city_id) : undefined, district_id ? Number(district_id) : undefined),
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler
    // staleTime: Infinity,
    // enabled: !!city_id || !!district_id,
  });
}
