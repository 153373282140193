import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination, Navigation } from "swiper"
import { MagazineType } from "pages/magazines/utils/models/magazineModel"
import { rootPaths } from "utils/constants/rootPaths"
import { makeUrl } from "utils/helpers/makeUrl"
import { useRouter } from "next/router"

import "swiper/css" // Swiper core styles
import "swiper/css/navigation" // For optional navigation buttons
import "swiper/css/pagination" // For optional pagination
import styles from "./magazines-mobile.module.scss"
import { cx } from "utils/cx"
import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import { dayjsFormats } from "utils/constants/dayjsFormats"
import localeData from "dayjs/plugin/localeData"
import localizedFormat from "dayjs/plugin/localizedFormat"
import "dayjs/locale/ru"
import "dayjs/locale/uz-latn"
import CustomLink from "common/custom-link"
import Image from "next/image"
import { imageLayouts, imageObjectFits } from "utils/constants/imageAttributes"

dayjs.extend(localizedFormat);
dayjs.extend(localeData);

dayjs.extend(advancedFormat);

type Props = {
  magazines: MagazineType[] | undefined;
};

const MagazinesMobile: React.FC<Props> = ({ magazines }) => {
  const { locale } = useRouter();

  if (!magazines || magazines.length === 0) {
    return null;
  }

  return (
    <div className={styles.container}>
      <Swiper
        spaceBetween={10}
        slidesPerView={1}
        pagination={{ clickable: true, el: ".custom_pagination" }}
        navigation={{
          prevEl: "#navigation_prev",
          nextEl: "#navigation_next"
        }}
        modules={[Pagination, Navigation]}
        className={styles.magazines_mobile_swiper}
      >
        {magazines?.map((magazine, index) => (
          <SwiperSlide key={index}>
            <CustomLink
              href={`${rootPaths.MAGAZINES}/${makeUrl(
                magazine.slug ? magazine.slug : magazine.name
              )}-${magazine.id}`}
            >
              <div className={styles.magazine}>
                <div className={styles.tags}>
                  <span>{magazine.category?.name[locale as keyof typeof magazine.category.name]}</span>
                </div>
                <div className={styles.image}>
                  <Image 
                    src={magazine.image}
                    alt={magazine.name} 
                    layout={imageLayouts.FILL}
                    objectFit={imageObjectFits.COVER}
                   />
                </div>
                <div className={styles.more_info}>
                  <div className={styles.title}>{magazine.name}</div>
                  <span className={styles.date}>
                    {locale === "uz"
                      ? dayjs(magazine.created_time, dayjsFormats.DATE).locale("uz-latn").format("DD MMMM YYYY")
                      : dayjs(magazine.created_time, dayjsFormats.DATE).locale("ru").format("DD MMMM YYYY")}
                  </span>
                </div>
              </div>
            </CustomLink>
          </SwiperSlide>
        ))}
      </Swiper>
      {magazines && magazines?.length > 1 && (
        <div className={styles.custom_pagination_container}>
          <div className={cx("custom_pagination", styles.custom_pagination)}></div>
        </div>
      )}
    </div>
  );
};

export default MagazinesMobile;
