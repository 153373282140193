import React from "react"
import {
  imageLayouts,
  imageLoadings,
  imageObjectFits,
} from "../../utils/constants/imageAttributes"
import { rootPaths } from "../../utils/constants/rootPaths"
import { makeUrl } from "../../utils/helpers/makeUrl"
import { OneBuilderModel } from "pages/builders/utils/models/oneBuilderModel"

import CustomLink from "common/custom-link"
import Image from "next/image"

import styles from "./builderCard.module.scss"

const BuilderCard: React.FC<OneBuilderModel> = (props) => {

  return (
    <CustomLink
      href={`${rootPaths.BUILDERS}/${makeUrl(props?.slug || props?.name)}`}
      className={`${styles.builder_card}`}
    >
      <div className={styles.builder_card_left}>
        <h3 className="!mb-0">{props.name}</h3>
      </div>
      <div className={styles.builder_card_right}>
        {props?.logo && (
          <Image
            src={props.logo}
            layout={imageLayouts.FILL}
            objectFit={imageObjectFits.CONTAIN}
            loading={imageLoadings.LAZY}
            alt={props.name}
          />
        )}
      </div>
    </CustomLink>
  )
}

export default BuilderCard
